import Swiper from 'swiper';
import { _x } from '@wordpress/i18n';
import domReady from '@wordpress/dom-ready';

domReady(() => {
  const carousel = document.querySelector('.fullwidth-header__carousel');

  if (carousel) {
    new Swiper(carousel, {
      direction: 'horizontal',
      loop: true,
      autoplay: {
        delay: 5000,
      },

      pagination: {
        el: '.fullwidth-header__carousel__navigation .swiper-pagination',
        clickable: 'true',
      },

      navigation: {
        nextEl: '.fullwidth-header__carousel__navigation .swiper-button-next',
        prevEl: '.fullwidth-header__carousel__navigation .swiper-button-prev',
      },

      a11y: {
        prevSlideMessage: _x(
          'See previous slide',
          'slider previous label',
          'vtx'
        ),
        nextSlideMessage: _x('See next slide', 'slider next label', 'vtx'),
        paginationBulletMessage: _x(
          'Go to slide {{index}}',
          'slide pagination label',
          'vtx'
        ),
        slideLabelMessage: _x(
          'Slide {{index}} of {{slidesLength}}',
          'slide label',
          'vtx'
        ),
      },
    });
  }
});
